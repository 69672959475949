





















































































































































































import { Vue, Component } from 'vue-class-decorator'
import { GlobalModule, PricesModule, AdsModule } from '@/store'

@Component({
  metaInfo: {
    title: 'Эффективность площадок'
  }
})
export default class SummaryAds extends Vue {
  public tableSummary: Array<object>
  public tableSummaryTiles: Array<object>
  public typeFilter: number
  public typeObjects: Array<object>
  public viewObjects: Array<object>
  public viewType: number
  public selectedObjects: Array<object>

  constructor() {
    super()
    this.typeFilter = 0
    this.viewType = 0
    this.viewObjects = [
      {
        name: 'Строками',
        typeId: 0
      },
      {
        name: 'Плитками',
        typeId: 1
      }
    ]
    this.typeObjects = [
      {
        name: 'Все',
        typeId: 0
      },
      {
        name: 'Участок',
        typeId: 1
      },
      {
        name: 'Коттедж',
        typeId: 2
      }
    ]
    this.selectedObjects = []
    this.tableSummaryTiles = [
      {
        text: 'Изображение',
        value: 'image',
        align: 'start',
        cellClass: 'cut-w',
        sortable: false
      },
      {
        text: 'Краткое описание',
        value: 'description',
        sortable: false,
        filter: (value, _search, item) => this.getFilterType(item)
      },
      {
        text: 'Опции',
        value: 'advanced',
        sortable: false
      }
    ]
    this.tableSummary = [
      {
        text: 'ID',
        value: 'priceId',
        align: 'start',
        sortable: false
      },
      {
        text: 'Название',
        value: 'name',
        sortable: false
      },
      {
        text: 'Реклама',
        value: 'saleId',
        align: 'center',
        sortable: false
      },
      {
        text: 'Тип',
        value: 'typeName',
        sortable: false,
        filter: (value, _search, item) => this.getFilterType(item)
      },
      {
        text: 'Менеджеры',
        value: 'usersTitles',
        sortable: false
      },
      {
        text: 'Опции',
        value: 'advanced',
        sortable: false
      }
    ]
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @AdsModule.Getter('getPrices') objectsList!: Array<object>
  @AdsModule.Action('requestPriceObjects') requestPriceObjects!: () => Promise<any>
  @AdsModule.Action('changeAdStatus') changeAdStatusStore!: (id: number | string) => Promise<any>

  created() {
    this.requestPriceObjects()
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  get tableSummaryHeaders() {
    if (this.viewType === 0) {
      return this.tableSummary
    } else {
      return this.tableSummaryTiles
    }
  }

  get formattedObject() {
    const objects = this.objectsList ?? []
    const newObjects = [] as any
    objects.forEach((object: any) => {
      const firstImage = JSON.parse(object.images)[0] === undefined ? '/front/images/upload/no-photo.jpg' : JSON.parse(object.images)[0].url
      newObjects.push({
        priceId: object.price_id,
        name: object.name,
        typeName: object.type_name,
        adress: object.adress,
        price: object.price,
        usersTitles: typeof object.users_titles === 'object' ? object.users_titles.join(', ') : object.users_titles,
        typeId: Number(object.type_id),
        advertStatus: object.sale_status === '1' ? 'Реклама: активна' : 'Реклама: на паузе',
        image: firstImage,
        saleId: object.sale_status
      })
    })
    return newObjects
  }

  changeAdStatus(priceId) {
    this.changeAdStatusStore(priceId)
      .then(() => {
        this.requestPriceObjects()
          .catch((error) => {
            this.$noty('error', error.message, 7000).show()
          })
        this.$noty('success', 'Статус рекламы изменён.', 7000).show()
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  getFilterType(item) {
    return item.typeId === this.typeFilter || !this.typeFilter
  }

  editObject(id: number) {
    this.$router.push(`/prices/edit/${id}`)
  }

  viewStatistic(id: number) {
    this.$router.push(`/ads/${id}`)
  }

  unloadXls() {
    this.$noty('error', 'Выгрузка не реализована', 7000).show()
  }
}
