var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('section',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"elevation-1 summary-table",attrs:{"headers":_vm.tableSummaryHeaders,"items":_vm.formattedObject,"items-per-page":10,"item-key":"priceId","show-select":"","single-select":false,"loading":_vm.loading,"loading-text":"Загрузка...","no-data-text":"Похоже тут ничего нет","footer-props":{
        itemsPerPageText: 'Элементов в таблице',
        itemsPerPageAllText: 'Все',
        itemsPerPageOptions: [5, 10, 15, -1]
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"action-toolbar"},[_c('v-toolbar',{staticClass:"pa-0",staticStyle:{"height":"70px"}},[_c('div',{staticClass:"d-flex justify-space-between align-center mt-3",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"mt-6 d-flex"},[_c('v-select',{staticClass:"mr-2",attrs:{"items":_vm.typeObjects,"item-text":"name","item-value":"typeId","label":"Тип"},model:{value:(_vm.typeFilter),callback:function ($$v) {_vm.typeFilter=$$v},expression:"typeFilter"}}),_c('v-select',{attrs:{"items":_vm.viewObjects,"item-text":"name","item-value":"typeId","label":"Отображение"},model:{value:(_vm.viewType),callback:function ($$v) {_vm.viewType=$$v},expression:"viewType"}})],1)])])],1)]},proxy:true},{key:"item.image",fn:function(ref){
      var item = ref.item;
return [_c('v-img',{staticClass:"object-image",attrs:{"src":item.image,"alt":"Объект"},on:{"click":function($event){return _vm.editObject(item.saleId)}}})]}},{key:"item.description",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"description-sector"},[_c('h2',[_vm._v(_vm._s(item.name))]),_c('h3',[_vm._v(_vm._s(item.price)+" ₽")]),_c('p',[_vm._v(_vm._s(item.typeName))]),_c('p',[_vm._v(_vm._s(item.adress))]),_c('h4',{staticClass:"mt-5 d-flex align-center"},[_c('span',{staticClass:"mr-2"},[_vm._v("Реклама:")]),(item.saleId == 1)?_c('v-chip',{staticClass:"white--text",attrs:{"color":"green"}},[_vm._v("Вкл.")]):_c('v-chip',{staticClass:"white--text",attrs:{"color":"red"}},[_vm._v("Выкл.")])],1)])]}},{key:"item.priceId",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.priceId))])]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.advanced",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"actions"},[(item.saleId == 1)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeAdStatus(item.priceId)}}},[_c('v-icon',{staticClass:"default",attrs:{"color":"red","title":"Выключить рекламу"}},[_vm._v(" mdi-pause-circle ")])],1):_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeAdStatus(item.priceId)}}},[_c('v-icon',{staticClass:"default",attrs:{"color":"green","title":"Включить рекламу"}},[_vm._v(" mdi-play-circle ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.viewStatistic(item.priceId)}}},[_c('v-icon',{staticClass:"default",attrs:{"title":"Статистика"}},[_vm._v(" mdi-poll ")])],1)],1)]}},{key:"item.typeName",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.typeName))])]}},{key:"item.managerName",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.managerName))])]}},{key:"item.saleId",fn:function(ref){
      var item = ref.item;
return [(item.saleId == 1)?_c('v-chip',{staticClass:"white--text",attrs:{"color":"green"}},[_vm._v("Вкл.")]):_c('v-chip',{staticClass:"white--text",attrs:{"color":"red"}},[_vm._v("Выкл.")])]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" из "+_vm._s(items.itemsLength)+" ")]}}],null,true),model:{value:(_vm.selectedObjects),callback:function ($$v) {_vm.selectedObjects=$$v},expression:"selectedObjects"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('h1',[_vm._v("Эффективность площадок")])])}]

export { render, staticRenderFns }